/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useState } from "react";
import EnterpriseCarouselItem from "./EnterpriseCarouselItem/EnterpriseCarouselItem";

import { FilterContext } from "../../context/filterContext";
import { fetchAll } from "../../services/enterprises";

const EnterpriseCarousel = () => {
  const [enterprises, setEnterprises] = useState([]);
  const { filter, setFilter } = useContext(FilterContext);
  const isLastPage = Boolean(
    enterprises.currentPage >= enterprises?.totalPages
  );
  const isFirstPage = Boolean(enterprises.currentPage === 1);

  const fetchData = useCallback(
    async (params = "") => {
      try {
        const queryParams = [];
        queryParams.push(params);

        if (filter.uf.length) {
          const ufs = filter.uf.map((uf) => {
            return `uf=${uf}`;
          });
          queryParams.push(...ufs);
        }

        if (filter.status_empreendimento.length) {
          const status_empreendimento = filter.status_empreendimento.map(
            (status_emp) => {
              return `status_empreendimento=${encodeURIComponent(status_emp)}`;
            }
          );
          queryParams.push(...status_empreendimento);
        }

        if (filter.cidade !== "Cidades") {
          queryParams.push(`cidade=${encodeURIComponent(filter.cidade)}`);
        }

        const enterprisesData = await fetchAll({
          params: queryParams.join("&"),
        });
        setEnterprises(enterprisesData);
        return enterprisesData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [filter]
  );

  useEffect(() => {
    fetchData();
  }, [filter, fetchData]);

  const nextPage = async () => {
    if (enterprises.currentPage >= enterprises.totalPages) {
      return;
    }

    const queryParams = `page=${enterprises.currentPage + 1}`;

    const enterpriseData = await fetchData(queryParams);

    setEnterprises(enterpriseData);
  };

  const previousPage = async () => {
    if (enterprises.currentPage <= 1) {
      return;
    }

    const queryParams = `page=${enterprises.currentPage - 1}`;

    const enterpriseData = await fetchData(queryParams);

    setEnterprises(enterpriseData);
  };

  return (
    <div>
      <EnterpriseCarouselItem
        enterprises={enterprises.data}
        id="empreendimentos"
      />
      {window.location.pathname === "/empreendimentos" && (
        <div className="container">
          <div className="row m-0">
            <div className="col-md-12 text-center mb-4">
              <button
                className="btn btn-primary mr-3"
                onClick={previousPage}
                disabled={isFirstPage}
              >
                &laquo;
              </button>
              <button
                className="btn btn-primary"
                onClick={nextPage}
                disabled={isLastPage}
              >
                &raquo;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterpriseCarousel;
