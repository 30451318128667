/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { FilterContext } from "../../../context/filterContext";
import { fetch } from "../../../services/filters";

const EnterpriseWebItem = () => {
  const { filter, setFilter } = useContext(FilterContext);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStates, setSelectedStates] = useState({});
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const enterpriseFilter = {
    uf: [],
    status: [],
    cidade: "",
    status_empreendimento: [],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch();
        setStates(response.ufs);
        setCities([{ cidade: "Cidades", uf: "" }, ...response.cidades]);
        setStatuses(response.status);
      } catch (error) {
        console.error("Erro ao carregar dados da API:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const statusObject = {};
    states.forEach((state) => {
      statusObject[state] = false;
    });

    setSelectedStates(statusObject);
  }, [states]);

  useEffect(() => {
    const statusesObject = {};
    statuses.forEach((status) => {
      statusesObject[status] = false;
    });

    setSelectedStatuses(statusesObject);
  }, [statuses]);

  useEffect(() => {
    const filteredStates = Object.keys(selectedStates).filter(
      (key) => selectedStates[key] == true
    );

    enterpriseFilter.uf = filteredStates;
    setFilter((currentFilter) => {
      return {
        ...currentFilter,
        uf: filteredStates,
      };
    });
  }, [selectedStates]);

  useEffect(() => {
    const filteredStatuses = Object.keys(selectedStatuses).filter(
      (key) => selectedStatuses[key] == true
    );

    enterpriseFilter.status_empreendimento = filteredStatuses;
    setFilter((currentFilter) => {
      return {
        ...currentFilter,
        status_empreendimento: filteredStatuses,
      };
    });
  }, [selectedStatuses]);

  function clearPagination() {
    // if (filter.pagination) {
    //   filter.pagination.page = 1;
    //   filter.pagination.limit = 4;
    // }
  }

  const handleStateChange = (state) => {
    clearPagination();
    // setFilter({ ...filter, state });
    setSelectedStates((selectedS) => {
      return {
        ...selectedS,
        [state]: !selectedS[state],
      };
    });
  };

  // const handleClearFilter = () => {
  //   clearPagination();
  //   setFilter("");
  // };

  const handleCityChange = (city) => {
    clearPagination();
    setSelectedCity(city);
    enterpriseFilter.cidade = city;
    setFilter((currentFilter) => {
      return {
        ...currentFilter,
        cidade: city,
      };
    });
    // setFilter({ ...filter, city });
  };

  const handleStatusChange = (status) => {
    clearPagination();
    // setFilter({ ...filter, status });
    setSelectedStatuses((selectedS) => {
      return {
        ...selectedS,
        [status]: !selectedS[status],
      };
    });
  };

  const styleBtn = {
    color: "#212529",
    backgroundColor: "#eaeaea",
    borderColor: "#eaeaea",
  };

  return (
    <div className="col-lg-12 d-none d-xl-block d-xxl-none text-center p-0 m-0">
      {states.map((state) => (
        <a
          key={state}
          className={`btn btn-outline-light mr4 f13 rox mr-2 ${
            filter.state === state ? "selected" : ""
          }`}
          href="#empreendimentos"
          // style={filter.state === state ? styleBtn : {}}
          style={selectedStates[state] ? styleBtn : {}}
          onClick={() => handleStateChange(state)}
        >
          {state}
        </a>
      ))}

      <select
        className="form-control d-inline-block border-d f13 rox mr-2 px-2"
        id="Cidades"
        style={{ width: "186px", marginTop: "-2px" }}
        onChange={(e) => handleCityChange(e.target.value)}
        value={filter.city}
      >
        {cities.map((city) => (
          <option key={city.cidade} value={city.cidade}>
            {city.cidade}
          </option>
        ))}
      </select>
      {statuses.map((status) => (
        <a
          key={status}
          className={`btn btn-outline-light mr4 f13 rox mr-2 ${
            // filter.status === status ? "selected" : ""
            selectedStatuses[status] ? "selected" : ""
          }`}
          href="#empreendimentos"
          style={selectedStatuses[status] ? styleBtn : {}}
          onClick={() => handleStatusChange(status)}
        >
          {status}
        </a>
      ))}

      {window.location.pathname === "/empreendimentos" ? (
        <a
          className="btn btn-success mr-1 mb-1 f13 fbold green1"
          href="https://api.whatsapp.com/send?phone=554137791190"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-light" style={{ fontSize: "1.1em" }}>
            WhatsApp
          </span>
          <i
            className="fa fa-whatsapp text-white ml-1"
            style={{ fontSize: "1.6em" }}
          ></i>
        </a>
      ) : (
        <a
          className="btn btn-outline-light f13 fbold rox px-3"
          href="/empreendimentos"
          // onClick={() => handleClearFilter()}
        >
          <span className="text-light">Ver todos</span>
        </a>
      )}
    </div>
  );
};

export default EnterpriseWebItem;
